import { library } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import LibrarySong from './LibrarySong';
const Library = ({libraryStatus, songs, setCurrentSong,audioRef, isPlaying, setSongs}) => {
    return(
        <div className={`library ${libraryStatus ? 'active-library ' : '' }`}>
            <h2>Library</h2>
            <div className="library-songs">
                {songs.map((song) => 
                <LibrarySong 
                song={song} 
                setCurrentSong={setCurrentSong} 
                key={song.id} 
                songs={songs} 
                id={song.id}
                audioRef={audioRef}
                isPlaying={isPlaying}
                setSongs={setSongs}
                /> )}
                
            </div>
        </div>
    )
}

export default Library;